import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private afAuth: AngularFireAuth, private navCtrl: NavController) { }


  async login(email, pwd) {
    var res = await this.afAuth.signInWithEmailAndPassword(email, pwd).catch(err => {return err});
    return res;
  }

  logout() {
    this.afAuth.signOut().then(res => {

    })
  }

  subscribeStatus() {
    this.afAuth.authState.subscribe(res => {
      if (res) {
        this.navCtrl.navigateRoot('/home');
      } else {
        this.navCtrl.navigateRoot('/login');
      }
    })
  }

}
